import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '@mui/material/Avatar'

import * as S from './styles'
import FormatCellValue from '../../../../components/DataTable/FormatCellValue'
import ActionColumnTable from '../../../../components/DataTable/ActionColumnTable'
import CustomPagination from '../../../../components/Dashboard/CustomPagination'
import { contentActions, searchActions } from '../../../../app/_actions'
import EditContentWrapper from '../../../../components/Dashboard/EditContent/EditContentWrapper'
import {
	getJustification,
	getStatusClassName
} from '../../../../utils/functions'
import { useTranslation } from 'react-i18next'

const defaultSortModel = {
	field: 'created_at',
	sort: 'desc'
}

function ManageContent({
	searchValue,
	searchValueRef,
	isUserContent,
	createNewContent,
	setCreateNewContent,
	checkboxSelection = false,
	selectedIds = null,
	setSelectedIds = null,
	visibleFields = null,
	skipPublishDialogOnFinish = false,
	onClose = null,
	onUpdate = null
}) {
	const [contentModalOpen, setContentModalOpen] = useState(false)
	const [selectedContentId, setSelectedContentId] = useState(null)
	const [sortModel, setSortModel] = useState(defaultSortModel)
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || 15
	})
	const [rowSelectionModel, setRowSelectionModel] = useState([])
	const { i18n } = useTranslation()

	const dispatch = useDispatch()
	const { contents } = useSelector(
		// @ts-ignore
		(state) => state.search
	)
	const { results, totalResults, loading } = contents
	// @ts-ignore
	const { user } = useSelector((state) => state.authentication)

	const handleOnUpdate = useCallback(
		(data) => {
			if (onUpdate) {
				onUpdate({ data, selectedContentId })
			}
		},
		[selectedContentId]
	)

	const getData = useCallback(() => {
		if (user) {
			if (
				searchValue !== searchValueRef.current &&
				paginationModel.page > 0
			) {
				setPaginationModel({
					page: 0,
					pageSize: parseInt(process.env.REACT_APP_PAGE_SIZE) || 15
				})
				searchValueRef.current = searchValue
				return
			}
			const params = {
				q: searchValue,
				contents_only: true
			}
			if (isUserContent) {
				params.user_id = user.id
			}
			dispatch(
				searchActions.getFilteredResults(
					params,
					paginationModel.page + 1,
					paginationModel.pageSize,
					i18n.language,
					sortModel?.field,
					sortModel?.sort,
					handleOnUpdate
				)
			)
			searchValueRef.current = searchValue
		}
	}, [
		dispatch,
		paginationModel,
		sortModel?.field,
		sortModel?.sort,
		user,
		searchValue,
		isUserContent,
		searchValueRef,
		i18n.language,
		handleOnUpdate
	])

	const handleSortModelChange = useCallback((sort) => {
		if (sort[0] && sort[0].field && sort[0].sort) {
			setSortModel(sort[0])
		} else {
			setSortModel(defaultSortModel)
		}
	}, [])

	const handleEdit = (item) => {
		setSelectedContentId(item.id)
	}

	const onRowSelectionModelChange = (newSelectedIds) => {
		setSelectedIds(newSelectedIds)
	}

	const resetContentId = useCallback(() => {
		setContentModalOpen(false)
		setSelectedContentId(null)
	}, [])

	const onComplete = () => {
		dispatch(contentActions.cleanContent())
	}

	let columns = [
		{
			field: 'actions',
			headerName: '',
			type: 'actions',
			getActions: (params) => [
				<ActionColumnTable
					key={params.row.index}
					item={params.row}
					onEdit={handleEdit}
					// checkStatus
				/>
			],
			flex: 0.4
		},
		{ field: 'title', headerName: 'Título', minWidth: 200, flex: 1 },
		{
			field: 'status',
			headerName: 'Status',
			valueFormatter: ({ value }) =>
				FormatCellValue({
					value,
					status: true
				}),
			minWidth: 150
		},
		{
			field: 'reprove_justification',
			headerName: 'Justificativa',
			valueFormatter: (props) => {
				const row = props.api.getRow(props.id)
				return getJustification(row)
			},
			minWidth: 150,
			sortable: false
		},
		{
			field: 'cover_id',
			headerName: 'Imagem',
			renderCell: ({ value }) =>
				value ? (
					<Avatar
						src={value}
						alt="imagem"
						sx={{ width: 64, height: 64 }}
					/>
				) : (
					<>-</>
				),
			sortable: false
		},
		{ field: 'author', headerName: 'Autor', minWidth: 200 },
		{
			field: 'keywords',
			headerName: 'Palavras-chave',
			flex: 0.5,
			minWidth: 200
		},
		{
			field: 'user',
			headerName: 'Usuária',
			flex: 0.5,
			minWidth: 150,
			valueGetter: (params) =>
				params.row.user ? params.row.user.name : ''
		},
		{
			field: 'organization',
			headerName: 'Instituição',
			flex: 0.5,
			minWidth: 150,
			valueGetter: (params) =>
				params.row.user && params.row.user.organization
					? params.row.user.organization.name
					: ''
		},
		{
			field: 'how_cite',
			headerName: 'Como citar',
			flex: 0.5,
			minWidth: 150,
			sortable: false
		},
		{
			field: 'year',
			headerName: 'Ano de publicação',
			flex: 0.4,
			minWidth: 150
		},
		{
			field: 'created_at',
			headerClassName: 'super-app-theme--header',
			headerName: 'Criado em',
			valueFormatter: ({ value }) =>
				FormatCellValue({
					value,
					format: 'DD/MM/YYYY HH:MM'
				}),
			flex: 0.5,
			minWidth: 150
		},
		{
			field: 'updated_at',
			headerName: 'Última atualização',
			valueFormatter: ({ value }) =>
				FormatCellValue({
					value,
					format: 'DD/MM/YYYY HH:MM'
				}),
			flex: 0.5,
			minWidth: 150
		}
	]

	if (isUserContent) {
		columns = columns.filter(
			(col) => !['user', 'organization'].includes(col.field)
		)
	}
	if (checkboxSelection) {
		columns = columns.filter((col) => col.field !== 'actions')
	}

	if (visibleFields) {
		columns = columns.filter((col) => visibleFields.includes(col.field))
	}

	useEffect(() => {
		getData()
	}, [getData])

	useEffect(() => {
		if (createNewContent) {
			setTimeout(() => {
				setCreateNewContent((currCreateNewContent) => {
					if (currCreateNewContent) {
						setSelectedContentId(null)
						setContentModalOpen(true)
					}
					return false
				}, 500)
			})
		}
	}, [createNewContent, setCreateNewContent])

	useEffect(() => {
		if (checkboxSelection) {
			setRowSelectionModel(selectedIds)
		}
	}, [selectedIds, checkboxSelection])

	return (
		<S.Panel>
			<S.Table isMini={checkboxSelection}>
				<S.StyledDataGrid
					rows={results ? results : []}
					// @ts-ignore
					columns={columns}
					getRowHeight={() => 'auto'}
					getRowClassName={getStatusClassName}
					hideFooter={true}
					onPaginationModelChange={setPaginationModel}
					sortingMode="server"
					onSortModelChange={handleSortModelChange}
					disableRowSelectionOnClick={true}
					loading={loading}
					checkboxSelection={checkboxSelection}
					keepNonExistentRowsSelected={checkboxSelection}
					onRowSelectionModelChange={
						checkboxSelection
							? onRowSelectionModelChange
							: undefined
					}
					rowSelectionModel={
						checkboxSelection ? rowSelectionModel : undefined
					}
				/>
			</S.Table>
			<S.PaginationContainer>
				<CustomPagination
					totalResults={totalResults}
					paginationModel={paginationModel}
					setPaginationModel={setPaginationModel}
				/>
			</S.PaginationContainer>
			<EditContentWrapper
				selectedContentId={selectedContentId}
				setSelectedContentId={setSelectedContentId}
				contentModalOpen={contentModalOpen}
				setContentModalOpen={setContentModalOpen}
				resetContentId={resetContentId}
				onUpdate={getData}
				onComplete={onComplete}
				onClose={onClose}
				skipPublishDialogOnFinish={skipPublishDialogOnFinish}
			/>
		</S.Panel>
	)
}

export default ManageContent
