import React, { useEffect, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import muiTheme from './styles/muiTheme'
import ReactGA from 'react-ga4'

import { alertActions, userActions } from './app/_actions'
import { collectionsConstants } from './app/_constants'
import history from './app/history'

import PublicApp from './components/PublicPages/PublicApp'
import ContentOrCollectionPage from './components/PublicPages/ContentOrCollectionPage'
import CollectionListPage from './components/PublicPages/CollectionListPage'

import Dashboard from './pages/Dashboard'

import Term from './pages/PublicPages/Term'
import Contact from './pages/PublicPages/Contact'
import Partners from './pages/PublicPages/Partners'
import Program from './pages/PublicPages/Program'
import About from './pages/PublicPages/About'
import SearchResult from './pages/PublicPages/SearchResult'
import ResetPassword from './pages/PublicPages/ResetPassword'
import EmailConfirm from './pages/PublicPages/EmailConfirm'

import { FilterProvider } from './context/advancedFilter'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Logout = () => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(userActions.logout())
	}, [dispatch])
	history.replace('/')
	//precisa atualizar a tela para trocar o menu do wordpress
	window.location.reload()
}

const App = () => {
	const dispatch = useDispatch()
	// @ts-ignore
	const { location } = useSelector((state) => state.alert)
	// @ts-ignore
	const { user, isVisitor } = useSelector((state) => state.authentication)

	ReactGA.initialize(process.env.REACT_APP_ANALYTICS)
	if (user) {
		ReactGA.set({
			userId: user.email
		})
	}

	useEffect(() => {
		dispatch(alertActions.setLocation(history.location.pathname))
		history.listen(() => {
			dispatch(alertActions.clear())
			dispatch(alertActions.setLocation(history.location.pathname))
			ReactGA.set({ page: history.location.pathname })
			// ReactGA.pageview(history.location.pathname)
		})
		dispatch(userActions.hasToken())
	}, [dispatch])

	if (!user && !isVisitor) {
		return <></>
	}
	const updateTracking = () => {
		ReactGA.ga('send', 'pageview', {
			page: window.location.pathname
		})
	}

	const isDashboard = location ? location.includes('painel') : false
	const collectionTypes = collectionsConstants.COLLECTION_TYPES
	return (
		<ThemeProvider theme={muiTheme}>
			<FilterProvider>
				<Router history={history}>
					<Suspense fallback={<></>}>
						<Route path="/" component={updateTracking} />
						<Switch>
							<Route exact path={['/', '/cadastro']}>
								<PublicApp />
							</Route>
							<Route
								path={[
									'/resultado-busca',
									'/resultado-busqueda'
								]}
							>
								<SearchResult />
							</Route>
							<Route path={['/conteudo/:url', '/contenido/:url']}>
								<ContentOrCollectionPage
									isCollection={false}
									isPreview={false}
								/>
							</Route>
							<Route path="/pre-visualizar/conteudo/:url">
								<ContentOrCollectionPage
									isCollection={false}
									isPreview={true}
								/>
							</Route>
							{Object.values(collectionTypes).map(
								(collectionTypeObject) => (
									<Route
										key={`preview_${collectionTypeObject.slug}`}
										path={`/pre-visualizar/${collectionTypeObject.slug}/:url`}
									>
										<ContentOrCollectionPage
											isCollection={true}
											isPreview={true}
										/>
									</Route>
								)
							)}
							{Object.values(collectionTypes).map(
								(collectionTypeObject) => (
									<Route
										key={collectionTypeObject.slug}
										path={[
											`/${collectionTypeObject.slug}/:url`,
											`/${collectionTypeObject.slug}-es/:url`
										]}
									>
										<ContentOrCollectionPage
											isCollection={true}
											isPreview={false}
										/>
									</Route>
								)
							)}
							{/* <Route path="/login" component={Sign} />
							<Route path="/login-es" component={Sign} /> */}
							<Route path="/logout" component={Logout} />
							<Route
								path="/mudar-senha"
								component={ResetPassword}
							/>
							<Route
								path="/confirma-email"
								component={EmailConfirm}
							/>
							<Route
								path="/termo-e-condicoes-de-uso"
								component={Term}
							/>
							<Route path="/parceiros" component={Partners} />
							<Route
								path="/iniciativa-proteja"
								component={Program}
							/>
							<Route path="/fale-conosco" component={Contact} />
							<Route path="/como-usar" component={About} />
							{Object.values(collectionTypes).map(
								(collectionTypeObject) => (
									<Route
										key={`${collectionTypeObject.slug}s`}
										path={[
											`/${collectionTypeObject.slug}s`,
											'/${collectionTypeObject.slug}s-es'
										]}
									>
										<CollectionListPage
											collectionTypeObject={
												collectionTypeObject
											}
										/>
									</Route>
								)
							)}
							<Route path={['/painel', '/panel']}>
								<Dashboard />
							</Route>
							{!isDashboard && <Redirect from="*" to="/" />}
						</Switch>
					</Suspense>
				</Router>
			</FilterProvider>
		</ThemeProvider>
	)
}

export default App
