import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import { Link } from '@mui/material'

import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditIcon from '@mui/icons-material/Edit'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import * as S from './styles'
import { collectionsActions } from '../../../app/_actions'
import theme from '../../../styles/theme'
import { STATUS } from '../../../constants'

import EditCollectionWrapper from '../../../components/Dashboard/EditCollection/EditCollectionWrapper'
import CollectionDetails from '../../../components/Dashboard/CollectionDetails'
import EditCollectionContents from '../../../components/Dashboard/EditSteps/EditCollectionContents'

function EditCollectionPage() {
	const { friendlyUrl } = useParams()
	const [publishConfirmOpen, setPublishConfirmOpen] = useState(false)
	const [collectionModalOpen, setCollectionModalOpen] = useState(false)
	const [confirmEditOpen, setConfirmEditOpen] = useState(false)
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
	const [confirmEditContentsOpen, setConfirmEditContentsOpen] =
		useState(false)
	const [editCollectionContentsOpen, setEditCollectionContentsOpen] =
		useState(false)

	const history = useHistory()
	const dispatch = useDispatch()
	// @ts-ignore
	const { object: collection } = useSelector((state) => state.collections)

	const collectionTypeSlug = collection.collection_type
		? collection.collection_type.slug
		: ''
	const collectionTypeName = collection.collection_type
		? collection.collection_type.name
		: ''
	const collectionTypePlural = collection.collection_type
		? collection.collection_type.plural
		: ''
	const collectionTypeGenre = collection.collection_type
		? collection.collection_type.genre
		: ''

	const onPreviewClick = () => {
		const url =
			collection.status === 1
				? `/${collectionTypeSlug}/`
				: `/pre-visualizar/${collectionTypeSlug}/`
		const win = window.open(url + collection.friendly_url, '_blank')
		if (win != null) {
			win.focus()
		}
	}

	const handleEdit = () => {
		if (
			[STATUS.APROVADO, STATUS.AGUARDANDO_APROVACAO].includes(
				collection.status
			)
		) {
			setConfirmEditOpen(true)
			return
		}
		setCollectionModalOpen(true)
	}
	const handleConfirmedEdit = () => {
		setConfirmEditOpen(false)
		setCollectionModalOpen(true)
	}
	const handleEditCollectionContents = () => {
		if (
			[STATUS.APROVADO, STATUS.AGUARDANDO_APROVACAO].includes(
				collection.status
			)
		) {
			setConfirmEditContentsOpen(true)
			return
		}
		setEditCollectionContentsOpen(true)
	}
	const handleConfirmedEditContents = () => {
		setConfirmEditContentsOpen(false)
		setEditCollectionContentsOpen(true)
	}
	const handleEditCollectionContentsClose = useCallback(() => {
		setEditCollectionContentsOpen(false)
	}, [setEditCollectionContentsOpen])

	const handleSubmitToPublish = useCallback(() => {
		setPublishConfirmOpen(true)
	}, [setPublishConfirmOpen])

	const handlePublish = useCallback(() => {
		setPublishConfirmOpen(true)
	}, [setPublishConfirmOpen])

	const handlePublishConfirmClose = useCallback(() => {
		setPublishConfirmOpen(false)
	}, [setPublishConfirmOpen])

	const handleConfirmEditClose = useCallback(() => {
		setConfirmEditOpen(false)
	}, [setConfirmEditOpen])

	const handleConfirmEditContentsClose = useCallback(() => {
		setConfirmEditContentsOpen(false)
	}, [setConfirmEditContentsOpen])

	const handleDelete = useCallback(() => {
		setConfirmDeleteOpen(true)
	}, [setConfirmDeleteOpen])

	const handleConfirmDeleteClose = useCallback(() => {
		setConfirmDeleteOpen(false)
	}, [setConfirmDeleteOpen])

	const doPublish = useCallback(() => {
		dispatch(
			collectionsActions.publishCollection(collection.id, () => {
				handlePublishConfirmClose()
			})
		)
	}, [collection.id, dispatch, handlePublishConfirmClose])

	const doDelete = useCallback(() => {
		handleConfirmDeleteClose()
		dispatch(
			collectionsActions.deleteCollection(collection.id, () => {
				dispatch(collectionsActions.cleanCollection())
				history.push(
					`/painel/coleções/${collectionTypePlural.toLowerCase()}`
				)
			})
		)
	}, [
		history,
		collection.id,
		dispatch,
		handleConfirmDeleteClose,
		collectionTypePlural
	])

	useEffect(() => {
		if (friendlyUrl) {
			dispatch(collectionsActions.getCollectionByUrl(friendlyUrl))
			return
		}
		dispatch(collectionsActions.cleanCollection())
	}, [dispatch, friendlyUrl])

	if (!collection.id) {
		return <></>
	}

	const ConfirmEditTitle = () =>
		collection.status === STATUS.APROVADO ? (
			<DialogTitle>
				Despublicar {collectionTypeGenre}&nbsp;
				{collectionTypeName.toLowerCase()} &quot;{collection.title}
				&quot;
			</DialogTitle>
		) : (
			<DialogTitle>
				Voltar {collectionTypeGenre}&nbsp;
				{collectionTypeName.toLowerCase()} &quot;{collection.title}
				&quot; para RASCUNHO
			</DialogTitle>
		)
	const ConfirmEditContent = () =>
		collection.status === STATUS.APROVADO ? (
			<DialogContent>
				<DialogContentText>
					Atenção! Est
					{collectionTypeGenre === 'a' ? 'a' : 'e'}&nbsp;
					{collectionTypeName.toLowerCase()} já foi aprovad
					{collectionTypeGenre}.
				</DialogContentText>
				<DialogContentText sx={{ my: 2 }}>
					Se editar {collectionTypeGenre}&nbsp;
					{collectionTypeName.toLowerCase()} &quot;
					{collection.title}&quot;, ela será despublicada e voltará a
					ser rascunho.
				</DialogContentText>
				<DialogContentText sx={{ my: 2 }}>
					Depois de editar, você poderá enviar novamente para
					aprovação. Tem certeza que quer editar?
				</DialogContentText>
			</DialogContent>
		) : (
			<DialogContent>
				<DialogContentText>
					Atenção! {collectionTypeGenre.toUpperCase()}
					&nbsp;
					{collectionTypeName.toLowerCase()} já está sendo analisada.
				</DialogContentText>
				<DialogContentText sx={{ my: 2 }}>
					Se editar {collectionTypeGenre}&nbsp;
					{collectionTypeName.toLowerCase()} &quot;
					{collection.title}&quot;, ela deixará de ser analisada e
					voltará a ser rascunho.
				</DialogContentText>
				<DialogContentText sx={{ my: 2 }}>
					Depois de editar, você poderá enviar novamente para
					aprovação. Tem certeza que quer editar?
				</DialogContentText>
			</DialogContent>
		)

	return (
		<div className="page">
			<Box
				sx={{
					fontSize: '150%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between'
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
					<Link
						href={`/painel/coleções/${collectionTypePlural.toLowerCase()}`}
					>
						<ChevronLeftIcon
							sx={{
								backgroundColor: theme.colors.primaryDark,
								color: theme.colors.light,
								borderRadius: 1,
								fontSize: '110%',
								mr: 1
							}}
						/>
					</Link>
					{collectionTypeName}
				</Box>
				<Box>
					{![STATUS.REJEITADA_ANALISE, STATUS.REJEITADO].includes(
						collection.status
					) && (
						<Button
							onClick={onPreviewClick}
							variant="outlined"
							startIcon={<PreviewOutlinedIcon />}
						>
							Pré-Visualizar
						</Button>
					)}
				</Box>
				<Button
					onClick={handleDelete}
					variant="outlined"
					color="error"
					startIcon={<DeleteOutlineOutlinedIcon />}
					disabled={[
						STATUS.REJEITADA_ANALISE,
						STATUS.REJEITADO
					].includes(collection.status)}
					sx={{ ml: 1 }}
				>
					Excluir
				</Button>
				<Button
					onClick={handleEdit}
					variant="outlined"
					startIcon={<EditIcon />}
					disabled={[
						STATUS.REJEITADA_ANALISE,
						STATUS.REJEITADO
					].includes(collection.status)}
					sx={{ ml: 1 }}
				>
					Editar
				</Button>
				<Button
					onClick={handleSubmitToPublish}
					variant="contained"
					color="secondary"
					disabled={[
						STATUS.AGUARDANDO_APROVACAO,
						STATUS.APROVADO,
						STATUS.REJEITADA_ANALISE,
						STATUS.REJEITADO
					].includes(collection.status)}
					sx={{ ml: 1 }}
				>
					Enviar
				</Button>
			</Box>
			<Box sx={{ mt: 3 }}>
				<CollectionDetails
					item={collection}
					isPage={true}
					handleEditCollectionContents={handleEditCollectionContents}
				/>
			</Box>
			<EditCollectionWrapper
				collectionTypeObject={collection.collection_type}
				collectionModalOpen={collectionModalOpen}
				setCollectionModalOpen={setCollectionModalOpen}
				handlePublish={handlePublish}
				handleDelete={handleDelete}
				isAtCollectionPage={true}
			/>
			<EditCollectionContents
				collection={collection}
				open={editCollectionContentsOpen}
				onClose={handleEditCollectionContentsClose}
			/>
			<Dialog
				open={publishConfirmOpen}
				onClose={handlePublishConfirmClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Confirmação do Termo de publicação
					{collectionTypeGenre === 'a' ? ' da ' : ' do '}
					{collectionTypeName.toLowerCase()}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Declaro que estou enviando esse material e ele está de
						acordo com o{' '}
						<S.StyledLink
							to="/termo-e-condicoes-de-uso"
							target="_blank"
						>
							Termo e condições de uso
						</S.StyledLink>{' '}
						assinado para o portal.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handlePublishConfirmClose}>
						Não concordo
					</Button>
					<Button onClick={doPublish} autoFocus>
						Concordo
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={confirmEditOpen} onClose={handleConfirmEditClose}>
				<ConfirmEditTitle />
				<ConfirmEditContent />
				<DialogActions>
					<Button
						variant="contained"
						color="warning"
						onClick={handleConfirmedEdit}
					>
						Sim, editar!
					</Button>
					<Button onClick={handleConfirmEditClose}>Cancelar</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={confirmEditContentsOpen}
				onClose={handleConfirmEditContentsClose}
			>
				<ConfirmEditTitle />
				<ConfirmEditContent />
				<DialogActions>
					<Button
						variant="contained"
						color="warning"
						onClick={handleConfirmedEditContents}
					>
						Sim, editar!
					</Button>
					<Button onClick={handleConfirmEditContentsClose}>
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={confirmDeleteOpen} onClose={handleConfirmDeleteClose}>
				<DialogTitle>
					Apagar {collectionTypeGenre}{' '}
					{collectionTypeName.toLowerCase()} &quot;{collection.title}
					&quot;
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Atenção, você não poderá recuperar est
						{
							collectionTypeGenre
						} {collectionTypeName.toLowerCase()} após apagá-l
						{collectionTypeGenre}!
					</DialogContentText>
					<DialogContentText>
						Tem certeza que quer apagar?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="error"
						onClick={doDelete}
					>
						Sim, apagar!
					</Button>
					<Button onClick={handleConfirmDeleteClose}>Cancelar</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default EditCollectionPage
