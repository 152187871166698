import React from 'react'
import Search from '../Search'

const PublicApp = () => {
	if (window.location.href.includes('cadastro')) {
		const loginElements = document.getElementsByClassName('proteja-login')
		if (loginElements) {
			// @ts-ignore
			loginElements[0].children[0].click()
			setTimeout(function () {
				const tabSignup =
					document.getElementsByClassName('cadastrar-button')
				if (tabSignup) {
					// @ts-ignore
					tabSignup[0].click()
				}
			}, 1000)
		}
	}
	return <Search />
}

export default PublicApp
